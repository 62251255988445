<template>
  <div class="main">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div class="search_box_all">
          <!--搜索-->
          <van-sticky>
            <headSearch :search-out="headGo" />
            <div class="search_box_top">
              <!--                <div class="search_box">-->
              <!--                  <img src="../../assets/images/search.png" class="search_box_img">-->
              <!--                  <input class="search_box_input" v-model="searchValue" type="text" placeholder="会员省钱购" @click="jumpToGoodsSearch">-->
              <!--                </div>-->
              <!--搜索-->
              <headSearch ref="headSearch" :search-out="headSearch" />
              <!--tab -->
              <van-tabs
                v-model="active"
                title-active-color="#F30002"
                title-inactive-color="#141F33"
                color="red"
                line-width="76px"
                line-height="0"
                @change="changeJdTab"
              >
                <!--(item.classData.id)-->
                <van-tab v-for="(item,index) in jdBannerList.classData" :key="index" :title="item.class_name" />
              </van-tabs>
            </div>
          </van-sticky>
          <!--轮播图-->
          <div v-if="active===0" class="circlediv">
            <div v-if="jdBannerList && jdBannerList.bannerData && jdBannerList.bannerData[0]" class="headimgdiv">
              <img :src="jdBannerList.bannerData[0].img_url" alt="" class="headimg" @click.prevent="jumpOutJd">
            </div>
          </div>
        </div>
        <!-- 空余 跳转-->
        <!--list 列表组件-->
        <div class="goodsList">
          <JdListRow
            v-if="goodsDetail && goodsParams"
            :jd-list="jdListSon"
            :jd-list-row-data="goodsDetail"
            :goods-params="goodsParams"
            :active="active"
            @sendPage="changePage"
          />
        </div>
      </div>
    </mescroll>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import headSearch from '@/component/head/search'
import Vue from 'vue'
import { Cell, List, Search, Tab, Tabs, Toast } from 'vant'
import JdListRow from '@/component/Goods/jdListRow'
import mescroll from '@/component/fresh_scroll/mescroll'
import { fetchJDHome, fetchJDHomeGoodsList } from '@/services/shop-cps/shop-jd-cps'

Vue.use(Tab).use(Tabs).use(Toast).use(List).use(Cell).use(Search)
export default {
  name: 'JdList',
  components: {
    JdListRow,
    mescroll,
    headSearch
  },
  data() {
    return {
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: '悦淘&京东',
        callbackGo: this.callbackGo
      },
      headSearch: {
        isShowSearch: true, // 输入框 title 控制
        isShowReturn: false, // 返回 上一页 显隐
        searchWidth: '94%',
        bgColor: '#fff', // 背景颜色
        placeholder: '会员省钱购',
        callbackClick: this.jumpToGoodsSearch // 回调
      },
      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },

      active: 0,
      jdBannerList: {},
      goodsDetail: [],
      goodsParams: {
        classId: '',
        page: 1,
        pageSize: 10,
        loading: false,
        finished: false,
        error: false
      },
      searchValue: '', // 搜索关键字
      jdListSon: '' // jdlist
    }
  },
  beforeMount() {
  },
  mounted() {
    this.getJdInfo()
    document.title = ''

    const _Router = {
      eventName: 'share',
      url: '/rr',
      image: '',
      title: '京东',
      des: '这里是京东'
    }
    this.$store.dispatch('RNrouter', _Router)
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    // 首页搜索框点击跳转搜索页
    jumpToGoodsSearch() {
      this.$router.push('./goodsSearch')
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.goodsDetail = []
          // 把请求到的数据添加到列表
          this.goodsDetail = this.goodsDetail.concat(arr)

          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const uid = window.localStorage.getItem('uid')
      const newParams = {
        classId: this.goodsParams.classId,
        page: pageNum,
        pageSize: pageSize,
        mid: uid
      }
      fetchJDHomeGoodsList(newParams).then(res => {
        if (res.code == 200) {
          successCallback(res.data)
        }
      })
    },

    jumpOutJd() {
      location.href = this.jdBannerList.bannerData[0].url
    },
    getJdInfo() {
      const uid = window.localStorage.getItem('uid')
      fetchJDHome({
        uid: uid || ''
      }).then(res => {
        if (res.code == 200) {
          this.jdBannerList = res.data
          this.goodsParams.classId = res.data.classData[0].id
          this.jdListSon = res.data.iconData // 10个 icon
        }
      })
    },

    // 获取商品列表
    getJdDetailList(sendId) {
      this.goodsParams.classId = sendId
      const newParams = {
        classId: this.goodsParams.classId,
        page: this.goodsParams.page,
        pageSize: this.goodsParams.pageSize
      }
      fetchJDHomeGoodsList(newParams).then(res => {
        if (res.code == 200) {
          if (res.data === null) { // 没数据则加载完毕
            this.goodsParams.finished = true
            return
          }
          if (res.data.length < 1 || !res.data) {
            this.goodsParams.finished = true
            return
          } else {
            this.goodsDetail = this.goodsDetail.concat(res.data)
          }
        }
      }).catch(error => {
        console.log(error)
        this.goodsParams.error = true
      }).finally(() => {
        this.goodsParams.loading = false
      })
    },

    changePage(e) {
      this.goodsParams.page = e.nowPage
      this.getJdDetailList(this.goodsParams.classId)
    },

    // tab切换商品list
    changeJdTab(index) {
      this.goodsDetail = []
      this.goodsParams.classId = this.jdBannerList.classData[index].id
      this.goodsParams.page = 1
      this.getJdDetailList(this.goodsParams.classId)
    },

    // 京东首页搜索
    jdSearch(val) {
      if (val.trim()) {
        // 存储搜索历史_丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅丅___________
        const newKeyList = JSON.parse(window.localStorage.getItem('searchHistoryKey'))
        this.searchHistoryList = newKeyList.length >= 1 ? newKeyList : []
        if (this.searchHistoryList.indexOf(val) < 0) {
          this.searchHistoryList.unshift(val)
          const localObj = JSON.stringify(this.searchHistoryList)
          window.localStorage.setItem('searchHistoryKey', localObj)
        }
        // 存储搜索历史_丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄丄___________
        this.$router.push({
          path: '/goodsList',
          query: {
            searchKeyword: val,
            fromPage: 'jdList'
          }
        })
      }
    }

  }
}
</script>
<style lang="less" scoped>
    .main {
      background: #F6F6F6;
    }
    .search_box_top{
      background: #fff;
      padding-top: 12px;
      box-sizing: border-box;
    }

    //.search_box{
    //  width: 340px;
    //  height: 32px;
    //  margin: 0px auto 2px;
    //  border: 1px solid #E75E62;
    //  border-radius: 18px;
    //  position: relative;
    //  background: #fff;
    //}
    .search_box_img{
      position: absolute;
      top: 18px;
      left: 16px;
      width: 20px;
      height: 20px;
    }
    .search_box_input{
      width: 84%;
      height: 30px;
      line-height: 34px;
      font-size: 12px;
      background: #fff;
    }
    .search_box_all{
      background: #fff;
      //margin-top: 12px;
    }
    .search_box_img {
      position: absolute;
      top: 6px;
      left: 8px;
      width: 20px;
      height: 20px;
    }
    .circlediv {
        width: 100%;
        overflow: hidden;
        text-align: center;
        background: #fff;
        height: 170px;
        position: relative;
        margin-top: -5px;
    }
    .circle {
        width: 140%;
        height: 50px;
        background: #E03022;
        border-radius: 0 0 50% 50%;
        border-top: none;
        position: relative;
        left: -20%;
    }
    .headimgdiv {
        position: absolute;
        width: 368px;
        height: 150px;
        border-radius: 8px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .headimg {
        width: 368px;
        height: 150px;
        border-radius: 8px;
    }

    .goodsList {
        margin-top: 10px;
        width: 375px;
    }
</style>
